/* global window */

import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { Button, Container, Header, Icon, Image, Label } from 'semantic-ui-react'
import styled from 'styled-components'

import { useQueryGetGameBySlug } from '../../services/apollo/components/game/getGameBySlug'
import { getImageUrl } from '../../services/aws'

const ContainerStyled = styled(Container)`
  background-color: black;
  min-height: 100vh;
`

const Buttons = styled.div`
  margin-top: 20px;
`

const GamePage = ({ isMobile }) => {
  const { gameSlug } = useParams()
  const { loading, game } = useQueryGetGameBySlug({ variables: { slug: gameSlug } })

  if (loading || !game) {
    return null
  }

  const img = getImageUrl({
    width: 350,
    height: 350,
    pathName: 'uploads/games',
    fileName: game.coverPicture,
  })

  return (
    <>
      <Helmet titleTemplate="%s - Struckd" defaultTitle="Struckd">
        <title>{game.title}</title>
        <meta name="description" content="Struckd" />
        <meta property="og:title" content={game.title} />
        <meta property="og:type" content="games.plays" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={img} />
        <link rel="apple-touch-icon" href={img} />
      </Helmet>
      <ContainerStyled fluid>
        <Image src={img} alt={game.title} />
        <Header
          as="h1"
          content={game.title}
          inverted
          style={{
            fontSize: isMobile ? '1.5em' : '1.7em',
            fontWeight: 'normal',
            marginBottom: 0,
            marginTop: isMobile ? '0.5em' : '1.5em',
          }}
        />

        <Header
          as="p"
          content={game.description}
          inverted
          style={{
            fontSize: isMobile ? '0.8em' : '1em',
            fontWeight: 'normal',
            marginTop: isMobile ? '0.5em' : '1.5em',
          }}
        />
        <a
          href={`https://struckd.onelink.me/5vMp?pid=my_media_source&c=share_game&af_dp=struckd%3A%2F%2Fgame%2F${game.slug}&deep_link_value=game%2F${game.slug}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button primary size="huge">
            Play
            <Icon name="right arrow" />
          </Button>
        </a>
        <Buttons>
          <Button as="div" labelPosition="right">
            <Button icon>
              <Icon name="heart" />
            </Button>
            <Label as="a" basic pointing="left">
              {game.numberOfLikes}
            </Label>
          </Button>
          <Button as="div" labelPosition="right">
            <Button icon>
              <Icon name="game" />
            </Button>
            <Label as="a" basic pointing="left">
              {game.numberOfPlays}
            </Label>
          </Button>
        </Buttons>
      </ContainerStyled>
    </>
  )
}

GamePage.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

export default GamePage
