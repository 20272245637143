/* eslint-disable no-console */
import { onError as _onError } from 'apollo-link-error'

const skipErrorLogging = ['PersistedQueryNotFound']

const onError = _onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(
      ({ message, locations, path }) =>
        skipErrorLogging.indexOf(message) === -1 &&
        message.search('auth/id-token-expired') === -1 &&
        console.error(
          new Error(
            `[GraphQL error]: Message: ${JSON.stringify(message)}, Location: ${JSON.stringify(
              locations,
            )}, Path: ${path}`,
          ),
        ),
    )
  }

  if (networkError) {
    if (networkError.result && networkError.result.errors) {
      networkError.result.errors.forEach(({ message }) => {
        if (message.search('auth/id-token-expired') === -1) {
          console.error(new Error(`${JSON.stringify(networkError)}'`))
        }
      })
    } else {
      console.error(new Error(`[Network error]: ${JSON.stringify(networkError)}`))
    }
  }
})

export default onError
