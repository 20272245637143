import { Base64 } from 'js-base64'

const CDN_IMAGES_BASE_URL = process.env.REACT_APP_IMAGE_HANDLER
const CDN_IMAGES_S3_BUCKET = 'thundr-production'

// eslint-disable-next-line import/prefer-default-export
export const getImageUrl = ({ width, height, pathName, fileName }) => {
  const imageRequest = JSON.stringify({
    bucket: CDN_IMAGES_S3_BUCKET,
    key: `${pathName}/${fileName}`,
    edits: {
      resize: {
        width: width ? Math.round(width) : 100,
        height: height ? Math.round(height) : 100,
        fit: 'cover',
      },
    },
  })

  return `${CDN_IMAGES_BASE_URL}/${Base64.btoa(imageRequest)}`
}
