import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { ApolloProvider } from '@apollo/react-hooks'
import apollo from '../services/apollo'
import GamePage from '../pages/GamePage'

const App = () => (
  <ApolloProvider client={apollo}>
    <Router>
      <Switch>
        <Route path="/games/:gameSlug">
          <Helmet titleTemplate="%s - Struckd" defaultTitle="Struckd">
            <meta name="description" content="Struckd" />
          </Helmet>
          <GamePage />
        </Route>
      </Switch>
    </Router>
  </ApolloProvider>
)

export default App
