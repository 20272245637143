import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import * as Yup from 'yup'

export const GET_GAME_BY_SLUG = gql`
  query getGameBySlug($slug: String) {
    getGameBySlug(slug: $slug) {
      id
      title
      slug
      description
      coverPicture
      numberOfLikes
      numberOfPlays
      userId {
        id
        username
        profilePicture
      }
    }
  }
`

export const useQueryGetGameBySlug = ({ variables, fetchPolicy }) => {
  const { loading, data } = useQuery(GET_GAME_BY_SLUG, {
    variables,
    fetchPolicy,
  })

  if (loading) {
    return { loading }
  }

  Yup.object({
    getGameBySlug: Yup.object({
      id: Yup.string().required(),
      title: Yup.string().required(),
      slug: Yup.string().required(),
      coverPicture: Yup.string().required(),
      numberOfLikes: Yup.number().required(),
      numberOfPlays: Yup.number().required(),
      userId: Yup.object({
        id: Yup.string().required(),
        username: Yup.string().nullable(), // TODO: this should be required, the DB is wrong
        profilePicture: Yup.string().nullable(),
      }).required(),
    }),
  }).validateSync(data)

  return { loading, game: data.getGameBySlug }
}
